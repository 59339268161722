<template>
  <div>
    <!-- fixed top header -->
    <div class="fixed-top-action" v-if="productForm && backUpProductData && JSON.stringify(productForm) !== JSON.stringify(backUpProductData)">
      <el-row type="flex" align="middle">
        <el-col :span="12" class="pl20">
          <div class="status-text">Өөрчлөлт хадгалах</div>
        </el-col>
        <el-col :span="12" class="text-right pr20">
          <el-button type="default" @click="confirmLeaveForm = true">Болих</el-button>
          <el-button type="success" @click="onChange('update')">Шинэчлэх</el-button>
        </el-col>
      </el-row>
    </div>
    <el-row>
      <el-col :span="20" :offset="2">
        <!-- Бүх хуудсанд ашиглагдах header section -->
        <el-row :gutter="20">
          <el-col :span="12">
            <custom-header title="Бүтээгдэхүүн засах" :tempSuppliers="this.$route.params.tempSuppliers" :tempBrands="this.$route.params.tempBrands"/>
          </el-col>
          <el-col :span="12" class="text-right">
            <el-tag type="success"><el-checkbox v-model="is_content_check">Хянасан</el-checkbox></el-tag>
          </el-col>
        </el-row>
        <!-- form error template -->
        <div class="form-error-container mb20" id="error-listener" ref="errorListener" v-if="errorChecker.length != 0">
          <header>Уучлаарай дутуу {{errorChecker.length}} зүйл байна</header>
          <ul class="error-list">
            <div v-for="(file, index) in errorChecker" :key="index">
              <li>{{file}}</li>
            </div>
          </ul>
        </div>
        <el-form label-position="top" :model="productForm" :rules="productRules" ref="productForm" @submit.native.prevent="onPressSave('productForm')">
          <el-row :gutter="20">
            <el-col :span="16">
              <div class="panel">
                <div class="panel-item">
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Монгол нэр" prop="name_mon" >
                        <el-input v-model="productForm.name_mon" placeholder="Говь ноолууран цамц"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Англи нэр" prop="name_eng" >
                        <el-input v-model="productForm.name_eng" placeholder="Gobi sweater"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Бүтээгдэхүүний sku">
                    <el-input v-model="productForm.supplier_sku" type="text" placeholder="Бүтээгдэхүүний sku"></el-input>
                  </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Бүртгэлийн нэр">
                    <el-input v-model="productForm.register_name" placeholder="Барааны бүртгэлийн нэр"></el-input>
                  </el-form-item>
                    </el-col>
                  </el-row>
                  <el-form-item label="Монгол тайлбар" prop="description_mon">
                    <vue-editor v-model="productForm.description_mon"></vue-editor>
                  </el-form-item>
                  <el-form-item label="Англи тайлбар" prop="description_eng">
                    <vue-editor v-model="productForm.description_eng"></vue-editor>
                  </el-form-item>
                  <el-form-item label="Категори" prop="category_id" class="position-relative">
                    <tree-selector v-model="productForm.category_id" :treeData="categories" multiple width="100%" />
                    <!-- <tree-selector v-model="productForm.category_id" :options="categories" :props="defaultProps" /> -->
                  </el-form-item>
                  <el-form-item label="Үндсэн категори" prop="main_category_id" class="position-relative">
                    <el-select v-model="productForm.main_category_id" width="100%">
                      <el-option v-for="category in categories" :key="category.uniq_id" :value="category.uniq_id" :label="category.name_mon">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <!-- <el-form-item label="Үндсэн категори" prop="main_category_id" class="position-relative">
                    <el-input v-model="productForm.sort" width="100%">
                    </el-input>
                  </el-form-item> -->
                </div>
              </div>
              <!-- Media section -->
              <div class="panel">
                <div class="panel-item">
                  <header>Зураг /бүтээгдэхүүн/</header>
                  <el-upload
                    class="mt5"
                    list-type="picture-card"
                    accept=".jpg,.jpeg,.png,.JPG,.JPEG"
                    action=""
                    :file-list="tempImages"
                    :auto-upload="false"
                    :on-preview="handlePictureCardPreview"
                    :on-remove="(file) => handleRemove(file, 'image')"
                    :on-change="(file) => getFile(file, 'image')">
                    <span>1020x1020</span>
                  </el-upload>
                  <loader :active="isUploading" />
                </div>
              </div>
              <div class="panel">
                <div class="panel-item">
                  <header>Зураг /сав, баглаа/</header>
                  <!-- <span>150x150 хэмжээтэй зураг оруулна уу</span> -->
                  <el-upload
                    class="mt5"
                    list-type="picture-card"
                    accept=".jpg,.jpeg,.png,.JPG,.JPEG"
                    action=""
                    :file-list="tempImagesPackage"
                    :auto-upload="false"
                    :on-preview="handlePictureCardPreview"
                    :on-remove="(file) => handleRemove(file, 'package')"
                    :on-change="(file) => getFile(file, 'package')">
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <loader :active="isUploadingPackage" />
                </div>
              </div>
              <!-- Price section -->
              <div class="panel">
                <div class="panel-item">
                  <header>Үнэ</header>
                  <el-row :gutter="20">
                    <el-col :span="8">
                      <el-form-item label="Татах үнэ" prop="get_price">
                        <el-input v-model="productForm.get_price" disabled type="number" placeholder="0₮">
                          <template slot="append">₮</template>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="Борлуулах үнэ" prop="price">
                        <el-input v-model="productForm.price" disabled type="number" placeholder="0₮">
                          <template slot="append">₮</template>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="Шимтгэлийн хувь">
                          <el-input placeholder="0%" v-model="percent" disabled>
                            <template slot="append">%</template>
                          </el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <div class="pb20">
                    <el-checkbox v-model="productForm.sale_check">Энэ хямдралтай бүтээгдэхүүн</el-checkbox>
                  </div>
                  <el-row :gutter="20" v-if="productForm.sale_check || productForm.discounted_price > 0">
                    <el-col :span="8">
                      <el-form-item label="Хямдарсан үнэ">
                        <el-input v-model="productForm.discounted_price" type="number" placeholder="0₮" disabled>
                          <template slot="append">₮</template>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="Эхлэх өдөр">
                        <el-date-picker type="datetime" v-model="productForm.start_date_sale" disabled></el-date-picker>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="Дуусах өдөр">
                        <el-date-picker type="datetime" v-model="productForm.end_date_sale" disabled></el-date-picker>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <!-- Group order section -->
              <div class="panel">
                <div class="panel-item">
                  <header>Групп захиалга</header>
                </div>
                <div class="panel-item">
                  <div class="mb20">
                    <el-checkbox v-model="productForm.is_group">Групп захиалга</el-checkbox>
                  </div>
                  <el-row :gutter="20" v-show="productForm.is_group">
                    <el-col :span="12">
                      <el-form-item label="Хямдрал дуусах хугацаа /цаг/">
                        <el-input type="number" v-model="productForm.group.duration_time" placeholder="24" @input="$forceUpdate()">
                          <template slot="append">цаг</template>
                        </el-input>
                      </el-form-item>
                      <el-form-item label="Хямдрах төрөл">
                        <el-radio v-model="productForm.group_order_discount_type" label="percent" @input="$forceUpdate()">Хувиар</el-radio>
                        <el-radio v-model="productForm.group_order_discount_type" label="amount" @input="$forceUpdate()">Үнийн дүнгээр</el-radio>
                      </el-form-item>
                      <el-form-item label="Багадаа хэдэн ширхэг авч болох">
                        <el-input type="number" v-model="productForm.group.min_item_count" placeholder="1" @input="$forceUpdate()">
                        </el-input>
                      </el-form-item>
                      <el-form-item label="Эхлэх хугацаа" prop="start_date">
                        <el-date-picker  @input="$forceUpdate()"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          v-model="productForm.group.start_date"
                          type="datetime"
                          placeholder="Эхлэх хугацаа">
                        </el-date-picker>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Хэдэн хүн">
                        <el-input type="number" v-model="productForm.group.user_count" placeholder="10" @input="$forceUpdate()"></el-input>
                      </el-form-item>
                      <el-form-item label="Хямдралийн хувь" v-if="productForm.group_order_discount_type === 'percent'">
                        <el-input type="number" v-model.number="productForm.group.saled_percentage" placeholder="30%" @input="$forceUpdate()">
                          <template slot="append">%</template>
                        </el-input>
                      </el-form-item>
                      <el-form-item label="Хямдралийн дүн" v-if="productForm.group_order_discount_type === 'amount'">
                        <el-input type="number" v-model.number="productForm.group.saled_price" placeholder="5000₮"  @input="$forceUpdate()">
                          <template slot="append">₮</template>
                        </el-input>
                      </el-form-item>
                      <el-form-item label="Ихдээ хэдэн ширхэг авч болох" prop="max_item_count">
                        <el-input type="number" v-model="productForm.group.max_item_count" placeholder="10" @input="$forceUpdate()">
                        </el-input>
                      </el-form-item>
                  <div class="pb20 pt30" >
                    <el-checkbox v-model="productForm.time_check">Дуусах хугацаа</el-checkbox>
                  </div>
                  <div class="variants-container pane" v-if="productForm.time_check">
                    <el-form-item prop="end_date">
                        <el-date-picker @input="$forceUpdate()"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          v-model="productForm.group.end_date"
                          type="datetime"
                          placeholder="Дуусах хугацаа">
                        </el-date-picker>
                      </el-form-item>
                  </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <!-- Variants section -->
              <div class="panel" v-if="productForm.variants.length > 0">
                <div class="panel-item">
                  <header>Бараа сонголт - {{productForm.variants.length}} төрөл</header>
                </div>
                <div class="panel-item">
                    <el-table
                      :data="productForm.variants"
                      @cell-click="onPressItem"
                      style="width: 100%">
                      <el-table-column
                        label="Нэр"
                        :show-overflow-tooltip="true"
                        prop="variant_name">
                      </el-table-column>
                      <el-table-column
                        label="Төлөв"
                        width="140"
                        prop="variant_status">
                      </el-table-column>
                      <el-table-column
                        label="Зарах үнэ"
                        width="140"
                        prop="variant_sellPrice">
                      </el-table-column>
                    </el-table>
                </div>
              </div>
              <!-- Inventory section -->
              <div class="panel">
                <div class="panel-item">
                  <header>Агуулах</header>
                  <!-- <el-form-item label="Barcode (ISBN, UPC, GTIN, etc.)" prop="barcode">
                    <el-input v-model="productForm.barcode" type="text"></el-input>
                  </el-form-item> -->
                </div>
                <div class="panel-item">
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <div class="mb20">
                        <el-tooltip class="item" effect="dark" placement="bottom-start" visible-arrow>
                          <div slot="content">Цахим бараа гэдэг нь биет бус буюу тасалбар, кино, <br/> дуу хөгжим, купон, видео сургалт гэх мэт барааг хэлнэ.<br/> Энэ сонголтыг сонгосон тохиолдолд хүргэлтийн үнэ <br/> 0 болохыг анхаарна уу.</div>
                          <el-switch v-model="productForm.is_physical" active-text="Энэ нь биетэй бүтээгдэхүүн" inactive-text="Цахим бараа"></el-switch>
                        </el-tooltip>
                      </div>
                    </el-col>
                    <el-col :span="12">
                      <div class="mb20">
                        <el-checkbox v-model="productForm.sell_check">Агуулахад байхгүй ч зарах</el-checkbox>
                      </div>
                    </el-col>
                  </el-row>
                </div>
                <div class="panel-item" v-if="productForm.is_physical">
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item
                        label="Жин">
                        <el-row :gutter="5">
                          <el-col>
                            <el-input v-model="productSize.Weight" type="number">
                              <el-select v-model="productSize.weightSuffix" slot="append">
                                <el-option label="kg" value="kg"></el-option>
                                <el-option label="gram" value="g"></el-option>
                              </el-select>
                            </el-input>
                          </el-col>
                        </el-row>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item
                        label="Урт">
                        <el-row :gutter="5">
                          <el-col>
                            <el-input v-model="productSize.Length" type="number">
                              <el-select v-model="productSize.lengthSuffix" slot="append">
                                <el-option label="cm" value="cm"></el-option>
                                <el-option label="inch" value="inch"></el-option>
                              </el-select>
                            </el-input>
                          </el-col>
                        </el-row>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item
                        label="Өргөн">
                        <el-row :gutter="5">
                          <el-col>
                            <el-input v-model="productSize.Width" type="number">
                              <el-select v-model="productSize.widthSuffix" slot="append">
                                <el-option label="cm" value="cm"></el-option>
                                <el-option label="inch" value="inch"></el-option>
                              </el-select>
                            </el-input>
                          </el-col>
                        </el-row>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item
                        label="Өндөр">
                        <el-row :gutter="5">
                          <el-col>
                            <el-input v-model="productSize.Height" type="number">
                              <el-select v-model="productSize.heightSuffix" slot="append">
                                <el-option label="cm" value="cm"></el-option>
                                <el-option label="inch" value="inch"></el-option>
                              </el-select>
                          </el-input>
                          </el-col>
                        </el-row>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </el-col>
            <!-- right sidebar -->
            <el-col :span="8">
              <!-- Product status section -->
              <div class="panel">
                <div class="panel-item">
                  <el-form-item label="Төлөв" prop="status">
                    <el-select v-model="productForm.status" class="block">
                      <el-option
                        v-for="(item, index) in statusOption"
                        :key="index"
                        :label="productForm.status === 'price_inactive' ? 'Үнэ батлаагүй' : productForm.status === 'brand_inactive' ? 'Брэнд хаалттай' : item.label"
                        :value="item.value"
                        :disabled="productForm.status === 'price_inactive' ? true : false">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="Tag сонгох">
                    <el-select v-model="productForm.tag_id" class="block" clearable>
                      <el-option
                        v-for="(tag, index) in tagData"
                        :key="index"
                        :label="tag.name_mon"
                        :value="tag.id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="Эрэмбэ">
                    <el-input type="number" placeholder="1..." v-model="productForm.sort"></el-input>
                  </el-form-item>
                </div>
              </div>
              <!-- Brand section -->
              <div class="panel">
                <div class="panel-item">
                  <header>Нийлүүлэгч & Брэнд</header>
                  <el-form-item label="Нийлүүлэгч" prop="supplier_id">
                    <el-select
                      class="block"
                      v-model="productForm.supplier_id"
                      @change="onChangeSupplier"
                      filterable
                      placeholder="Таван богд"
                      :loading="supplierLoading">
                      <el-option
                        v-for="(item, index) in suggestedSupplier"
                        :key="index"
                        :label="item.supplier_monName"
                        :value="item.supplier_id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="Collection" prop="collection_id">
                    <el-select
                      class="block"
                      v-model="productForm.collections"
                      filterable
                      multiple
                      placeholder="Collection">
                      <el-option
                        v-for="(item, index) in collectionList"
                        :key="index"
                        :label="item.name_mon"
                        :value="item.collection_id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="Брэнд" prop="brand_id" v-if="productForm.supplier_id != ''">
                    <loader :active="isLoadBrand" />
                    <el-select
                      v-model="productForm.brand_id"
                      filterable
                      class="block">
                      <el-option
                        v-for="(item, index) in brandOptions"
                        :key="index"
                        :label="item.brand_monName"
                        :value="item.brand_id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="Агуулах" prop="warehouse_id" v-if="productForm.supplier_id != ''">
                    <loader :active="isLoadBrand" />
                    <el-select
                      v-model="productForm.warehouse_id"
                      filterable
                      multiple
                      disabled
                      class="block">
                      <el-option
                        v-for="(warehouse, indexWarehouse) in warehouses"
                        :key="indexWarehouse"
                        :label="warehouse.name_mon"
                        :value="warehouse.warehouse_id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </div>
              <!-- Properties section -->
              <div class="panel">
                <div class="panel-item pb20">
                  <header>Шинж чанар</header>
                  <el-form-item label="Нэр">
                    <el-input v-model="property.name" placeholder="Насны ангилал, Хүйс ..."></el-input>
                  </el-form-item>
                  <el-form-item label="Утга">
                    <el-input v-model="property.value" placeholder="Том хүн, Эмэгтэй ..."></el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="default" class="block" @click="addProperty">Шинж нэмэх</el-button>
                  </el-form-item>
                </div>
                <div class="panel-item" v-for="(property, index) in productForm.properties" :key="index">
                  <div class="property-item">
                    <el-row :gutter="10" type="flex" align="middle">
                      <el-col :span="18">
                        <div>
                          <strong>{{property.name}}</strong>
                        </div>
                        <div>
                          <span class="text-color-secondary">{{property.value}}</span>
                        </div>
                      </el-col>
                      <el-col :span="6" class="text-right">
                        <i class="el-icon-delete cursor-pointer" @click="removeProperty(property)"></i>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>
               <!-- dialog view history -->
                <div class="panel-item pb20">
                  <el-form-item>
                    <el-button v-if="historyTotal != '0'" type="success" class="block" @click="dialogVisibleHistory = true">Түүх үзэx</el-button>
                  </el-form-item>
                  </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-button type="danger" @click="onChange('delete')">Устгах</el-button>
            </el-col>
            <el-col :span="12">
              <div class="text-right border-top pt20">
                <el-button type="default" @click="confirmLeaveForm = true">Буцах</el-button>
                <el-button type="success" @click="onChange('update')">Шинэчлэх</el-button>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </el-col>
    </el-row>
    <!-- Confirm discard changes dialog -->
    <el-dialog
      :title="isChanging ? 'Бүтээгдэхүүн шинэчлэх' : 'Бүтээгдэхүүн устгах'"
      :visible.sync="confirmDeleteProduct"
      width="35%"
    >
      <div>
        <strong>{{productForm.name_mon}}</strong> бүтээгдэхүүнийг {{ isChanging ? 'шинэчлэхдээ' : 'устгахдаа'}} шалтгаанаа заавал бичнэ үү?
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmDeleteProduct = false" class="mr10"
          >Болих</el-button
        >
        <el-button  v-if="isChanging" type="success" @click="onPressSave('productForm', 'productRules')">
          Хадгалах
        </el-button>
        <el-button v-else type="danger" :disabled="isDisabled" @click="deleteProduct('productForm', 'productRules')">
          Устгах
        </el-button>
      </span>
      <el-row :gutter="20">
        <el-col :span="24" class="mt20">
          <el-form :model="productForm" :rules="productRules" ref="productForm">
            <el-form-item prop="desc" label="Шалтгаан">
              <el-input v-model="productForm.desc" type="textarea" :rows="2" :placeholder="isChanging ? 'Шинэчилж буй шалтгаанаа бичнэ үү ...' : 'Устгаж буй шалтгаанаа бичнэ үү ...'"></el-input>
            </el-form-item>
          </el-form>
          <span v-if="!isChanging" style="color: red">Tа устгах товч дарвал тухайн бүтээгдэхүүний бүх хувилбарууд дагаж устагдахыг анхаарна уу.</span>
          <el-table
          v-if="!isChanging"
          :data="productForm.variants"
          style="width: 100%">
          <el-table-column>
            <template slot-scope="scope">
              {{scope.row.variant_name}}
            </template>
          </el-table-column>
          <el-table-column>
            <template slot-scope="scope">
              {{scope.row.variant_sku}}
            </template>
          </el-table-column>
        </el-table>
        </el-col>
      </el-row>
    </el-dialog>
    <el-dialog
      title="Өөрчлөлтүүдийг хадгалах уу"
      :visible.sync="confirmLeaveForm"
      width="50%">
      <span>Хэрвээ та устгах товч дарвал таны хийсэн бүх өөрчлөлт устагдахыг анхаарна уу.</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmLeaveForm = false" class="mr10">Үргэлжлүүлэх</el-button>
        <el-button type="danger" @click="onPressClose">Буцах</el-button>
      </span>
    </el-dialog>
    <!-- Image view dialog -->
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
    <loader :active="isLoading" is-full-screen />
    <el-dialog title="Түүх харах" center :visible.sync="dialogVisibleHistory" width="50%">
        <el-table :data="historyData"
        style="width: 100%">
          <el-table-column label="Хэрэглэгч" width="240">
            <template slot-scope="scope">
              <span>{{scope.row.user_name}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="type" label="Үйлдэл"></el-table-column>
          <el-table-column label="Date" prop="created_at" width="180">
          </el-table-column>
          <el-table-column>
           <template slot-scope="scope"><el-button size="small" type="primary" @click="showHistoryDialog(scope.row._id)">Дэлгэрэнгүй</el-button></template>
          </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="exitHistory">Хаах</el-button>
      </span>
    </el-dialog>
    <!-- detail view History -->
    <el-dialog title="Түүх дэлгэрэнгүй харах" center :visible.sync="historyDetail" :width="historyDetailoldData === null ? '40%' : '80%'">
      <el-row :gutter="20">
      <loader :active="historyLoading" is-screen />
        <el-col :span="12" v-if="historyDetailoldData !== null">
          <el-row :gutter="20">
            <el-card>
              <el-form :model="historyDetailoldData" :disabled="true">
                <el-row :gutter="20">
                  <span><strong>Хуучин</strong> - {{historyDetailoldData.created_at}}</span>
                  <el-tag class="ml20" :type="historyDetailoldData.status === 'inactive' ? 'info' : historyDetailoldData.status === 'price_inactive' ? 'warning' : 'primary'">{{historyDetailoldData.status === 'inactive' ? 'Идэвхгүй' : historyDetailoldData.status === 'price_inactive' ? 'Үнэ батлаагүй' : 'Идэвхтэй'}}
                  </el-tag>
                </el-row>
                <el-form-item label="Зураг" class="mt20">
                  <el-image style="width: 200px; height: 200px" :src="Array.isArray(historyDetailoldData.image) ? historyDetailoldData.image[0] : historyDetailoldData.image"></el-image>
                </el-form-item>
                <el-col :span="12">
                  <el-form-item label="Нэр">
                    <el-input v-model="historyDetailoldData.name_mon">₮</el-input>
                  </el-form-item>
                  <el-form-item label="Үнэ">
                    <el-input v-model="historyDetailoldData.price">₮</el-input>
                  </el-form-item>
                  <el-form-item label="Үндсэн ангилал">
                    <el-select v-model="historyDetailoldData.main_category_id" class="block">
                      <el-option
                        v-for="(category, index) in categories"
                        :key="index"
                        :value="category.uniq_id"
                        :label="category.uniq_id === historyDetailoldData.main_category_id ? category.name_mon : ''"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="Нийлүүлэгч">
                    <el-select v-model="historyDetailoldData.supplier_id" class="block">
                      <el-option
                        v-for="(item, index) in suggestedSupplier"
                        :key="index"
                        :value="item.supplier_id"
                        :label="item.supplier_id === historyDetailoldData.supplier_id ? item.supplier_monName : ''">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="Тайлбар">
                    <el-input type="textarea" v-model="historyDetailoldData.description_mon"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Product name">
                    <el-input v-model="historyDetailoldData.name_eng">₮</el-input>
                  </el-form-item>
                  <el-form-item label="Хямдралтай үнэ">
                    <el-input v-model="historyDetailoldData.discounted_price">₮</el-input>
                  </el-form-item>
                  <!-- <el-form-item label="Ангилал">
                    <tree-selector v-if="categories.length > 0" v-model="historyDetailoldData.category_id" :treeData="categories ? categories : []" multiple width="100%" />
                  </el-form-item> -->
                  <el-form-item label="Брэнд" v-if="brandOptions.length > 0">
                    <el-select class="block" v-model="historyDetailoldData.brand_id">
                      <el-option
                        v-for="(brand, index) in brandOptions"
                        :key="index"
                        :label="brand.brand_id === historyDetailoldData.brand_id ? brand.brand_monName : ''"
                        :value="brand.brand_id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="Агуулахад байхгүй ч зарах">
                    <el-checkbox v-model="historyDetailoldData.sell_check">Агуулахад байхгүй ч зарах</el-checkbox>
                  </el-form-item>
                   <el-form-item label="Эрэмбэ">
                    <el-input v-model="historyDetailoldData.sort">Агуулахад байхгүй ч зарах</el-input>
                  </el-form-item>
                </el-col>
              </el-form>
            </el-card>
          </el-row>
        </el-col>
        <el-col :span="historyDetailoldData === null ? 24 : 12" class="l10" v-if="historyDetailnewData !== null">
          <el-row :gutter="20">
            <el-card>
              <el-form :model="historyDetailnewData" :disabled="true">
                <el-row :gutter="20">
                  <span><strong>Шинэ</strong> - {{historyDetailnewData.created_at}}</span>
                  <el-tag class="ml20" :type="historyDetailnewData.status === 'inactive' ? 'info' : historyDetailnewData.status === 'price_inactive' ? 'warning' : 'primary'">{{historyDetailnewData.status === 'inactive' ? 'Идэвхгүй' : historyDetailnewData.status === 'price_inactive' ? 'Үнэ батлаагүй' : 'Идэвхтэй'}}
                  </el-tag>
                </el-row>
                <el-form-item label="Зураг" class="mt20">
                  <el-image style="width: 200px; height: 200px" :src="Array.isArray(historyDetailnewData.image) ? historyDetailnewData.image[0] : historyDetailnewData.image"></el-image>
                </el-form-item>
                <el-col :span="12">
                  <el-form-item label="Нэр" :class="changeLog(historyDetailoldData ? historyDetailoldData.name_mon : historyDetailnewData.name_mon, historyDetailnewData.name_mon) ? 'labelColor' : ''">
                    <el-input v-model="historyDetailnewData.name_mon">₮</el-input>
                  </el-form-item>
                  <el-form-item label="Үнэ" :class="changeLog(historyDetailoldData ? historyDetailoldData.price : historyDetailnewData.price, historyDetailnewData.price) ? 'labelColor' : ''">
                    <el-input v-model="historyDetailnewData.price">₮</el-input>
                  </el-form-item>
                  <el-form-item label="Үндсэн ангилал" :class="changeLog(historyDetailoldData ? historyDetailoldData.main_category_id : historyDetailnewData.main_category_id, historyDetailnewData.main_category_id) ? 'labelColor' : ''">
                    <el-select class="block" v-model="historyDetailnewData.main_category_id">
                      <el-option v-for="(category, index) in categories" :key="index" :value="category.uniq_id" :label="category.uniq_id === historyDetailnewData.main_category_id ? category.name_mon : ''"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="Нийлүүлэгч" :class="changeLog(historyDetailoldData ? historyDetailoldData.supplier_id : historyDetailnewData.supplier_id, historyDetailnewData.supplier_id) ? 'labelColor' : ''">
                    <el-select class="block" v-model="historyDetailnewData.supplier_id">
                      <el-option
                        v-for="(item, index) in suggestedSupplier"
                        :key="index"
                        :value="item.supplier_id"
                        :label="item.supplier_id === historyDetailnewData.supplier_id ? item.supplier_monName : ''">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="Тайлбар" :class="changeLog(historyDetailoldData ? historyDetailoldData.description_mon : historyDetailnewData.description_mon, historyDetailnewData.description_mon) ? 'labelColor' : ''">
                    <el-input type="textarea" v-model="historyDetailnewData.description_mon"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Product name" :class="changeLog(historyDetailoldData ? historyDetailoldData.name_eng : historyDetailnewData.name_eng , historyDetailnewData.name_eng) ? 'labelColor' : ''">
                    <el-input v-model="historyDetailnewData.name_eng">₮</el-input>
                  </el-form-item>
                  <el-form-item label="Хямдралтай үнэ" :class="changeLog(historyDetailoldData ? historyDetailoldData.discounted_price : historyDetailnewData.discounted_price, historyDetailnewData.discounted_price) ? 'labelColor' : ''">
                    <el-input v-model="historyDetailnewData.discounted_price">₮</el-input>
                  </el-form-item>
                  <!-- <el-form-item label="Ангилал">
                    <tree-selector v-if="categories.length > 0" v-model="historyDetailnewData.category_id" :treeData="categories ? categories : []" multiple width="100%" />
                  </el-form-item> -->
                  <el-form-item label="Брэнд" :class="changeLog(historyDetailoldData ? historyDetailoldData.brand_id : historyDetailnewData.brand_id, historyDetailnewData.brand_id) ? 'labelColor' : ''">
                    <el-select v-if="brandOptions.length > 0" class="block" v-model="historyDetailnewData.brand_id">
                      <el-option
                        v-for="(item, index) in brandOptions"
                        :key="index"
                        :label="item.brand_id === historyDetailnewData.brand_id ? item.brand_monName : ''"
                        :value="item.brand_id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="Агуулахад байхгүй ч зарах" :class="changeLog(historyDetailoldData ? historyDetailoldData.sell_check : historyDetailnewData.sell_check, historyDetailnewData.sell_check) ? 'labelColor' : ''">
                    <el-checkbox v-model="historyDetailnewData.sell_check">Агуулахад байхгүй ч зарах</el-checkbox>
                  </el-form-item>
                   <el-form-item label="Эрэмбэ" :class="changeLog(historyDetailoldData ? historyDetailoldData.sort : historyDetailnewData.sort, historyDetailnewData.sort) ? 'labelColor' : ''">
                    <el-input v-model="historyDetailnewData.sort">Агуулахад байхгүй ч зарах</el-input>
                  </el-form-item>
                </el-col>
              </el-form>
            </el-card>
          </el-row>
        </el-col>
      </el-row>
      <el-row :gutter="20" class="mt10">
        <el-col align="middle">
          <el-button type="danger" @click="exitHistoryDetail">Хаах</el-button>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>
<script>
import { VueEditor } from 'vue2-editor'
import services from '../../../helpers/services'
import { handlers } from '../../../helpers/custom'
import CustomHeader from '../../../components/common/customHeader'
import TreeSelector from '../../../components/common/treeSelector'
import Loader from '../../../components/common/loader'
import { ElInputTag } from 'el-input-tag'

const PRODUCT_DATA = {
  group_order_discount_type: '',
  group: {
    user_count: '',
    saled_price: 0,
    saled_percentage: 0,
    min_item_count: '',
    max_item_count: '',
    end_date: '',
    start_date: '',
    duration_time: ''
  },
  tag_id: 0,
  is_group: false,
  desc: '',
  collections: [],
  name_mon: '',
  name_eng: '',
  register_name: '',
  supplier_sku: '',
  description_mon: '',
  description_eng: '',
  price: '',
  get_price: '',
  sell_check: false,
  sale_check: false,
  discounted_price: '',
  image: [],
  package_image: [],
  sku: '',
  is_physical: true,
  brand_id: '',
  supplier_id: '',
  barcode: '',
  status: 'active',
  category_id: '',
  main_category_id: '',
  variants: [],
  properties: [],
  physical_size: {
    Weight: '',
    Length: '',
    Width: '',
    Height: ''
  }
}
export default {
  name: 'addProduct',
  components: {
    CustomHeader,
    TreeSelector,
    Loader,
    VueEditor,
    // eslint-disable-next-line vue/no-unused-components
    ElInputTag
  },
  data () {
    return {
      warehouses: [],
      is_content_check: false,
      redClass: false,
      historyDetailData: [],
      historyLoading: false,
      tagData: [],
      collectionList: [],
      percent: '',
      taxPaid: '',
      time_check: false,
      selectedLogId: '',
      selectedLog: [],
      historyDetailoldData: null,
      historyDetailnewData: null,
      historyDataDetail: [],
      historyData: [],
      historyName: 'product',
      dialogVisibleHistory: false,
      historyDetail: false,
      historyTotal: '',
      isDisabled: false,
      isChanging: false,
      property: {
        name: '',
        value: ''
      },
      visibleSuggest: false,
      categoryResult: [],
      searchValue: '',
      defaultProps: {
        parent: 'parent',
        value: 'uniq_id',
        label: 'name_mon',
        children: 'childrens'
      },
      isLoading: false,
      isUploading: false,
      isUploadingPackage: false,
      isLoadBrand: false,
      confirmDeleteProduct: false,
      confirmLeaveForm: false,
      dialogImageUrl: '',
      isNewForm: true,
      dialogVisible: false,
      categories: [],
      tempImages: [],
      tempImagesPackage: [],
      options: [],
      statusOption: [],
      suggestedSupplier: [],
      supplierLoading: false,
      brandOptions: [],
      productSize: {
        Weight: '',
        Length: '',
        Width: '',
        Height: '',
        weightSuffix: 'kg',
        heightSuffix: 'cm',
        widthSuffix: 'cm',
        lengthSuffix: 'cm'
      },
      backUpProductData: JSON.parse(JSON.stringify(PRODUCT_DATA)),
      productForm: JSON.parse(JSON.stringify(PRODUCT_DATA)),
      productRules: {
        desc: [
          { required: true, message: 'Шалтгаанаа заавал оруулна уу', trigger: 'change' }
        ],
        name_mon: [
          { required: true, message: 'Монгол нэрээ оруулна уу', trigger: 'change' }
        ],
        name_eng: [
          { required: true, message: 'Англи нэрээ оруулна уу', trigger: 'change' }
        ],
        description_mon: [
          { required: true, message: 'Монгол тайлбараа оруулна уу', trigger: 'change' }
        ],
        description_eng: [
          { required: true, message: 'Англи тайлбараа оруулна уу', trigger: 'change' }
        ],
        category_id: [
          { required: true, message: 'Категорио сонгоно уу', trigger: 'blur' }
        ],
        main_category_id: [
          { required: true, message: 'Үндсэн категорио сонгоно уу', trigger: 'blur' }
        ],
        price: [
          { required: true, message: 'Үнээ оруулна уу', trigger: 'change' }
        ],
        get_price: [
          { required: true, message: 'Татах үнээ оруулна уу', trigger: 'change' }
        ],
        status: [
          { required: true, message: 'Төлөвөө сонгоно уу', trigger: 'change' }
        ],
        supplier_id: [
          { required: true, message: 'Нийлүүлэгчээ сонгоно уу', trigger: 'change' }
        ],
        brand_id: [
          { required: true, message: 'Брэндээ сонгоно уу', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    if (this.$route.params.product_id) {
      this.getProductData(this.$route.params.product_id)
      this.getOptions()
      this.getHistory(this.$route.params.product_id)
      this.getTag(0, 1000)
      this.filterSupplier()
    }
    this.getCollectionLists()
  },
  computed: {
    errorChecker () {
      var errors = []
      if (!this.isNewForm) {
        if (this.tempImages.length === 0) {
          errors.push('Зураг оруулна уу')
        }
        if (this.productForm.properties.length === 0) {
          errors.push('Шинж чанар оруулна уу')
        }
      }
      return errors
    }
  },
  methods: {
    getWarehousesBySupplierId () {
      this.loading = true
      const body = {
        supplier_id: [this.productForm.supplier_id],
        size: 100,
        from: 0
      }
      services.getWarehousesBySupplierId(body).then(response => {
        this.loading = false
        if (response.status === 'success' && response.data) {
          this.warehouses = response.data
        } else {
          this.warehouses = []
        }
      })
    },
    changeLog (old, shine) {
      if (old !== shine) {
        return true
      } else return false
    },
    getTag (from, size) {
      const query = '?from=' + from + '&size=' + size
      services.getTag(query).then((response) => {
        this.tagData = response.datas
      })
    },
    exitHistory () {
      this.dialogVisibleHistory = false
    },
    exitHistoryDetail () {
      this.historyDetail = false
      this.historyDetailnewData = null
      this.historyDetailoldData = null
    },
    showHistoryDialog (id) {
      this.historyDetail = true
      this.getHistoryDetail(id)
    },
    onChange (type) {
      if (type === 'update') {
        this.confirmDeleteProduct = true
        this.isChanging = true
      } else {
        this.confirmDeleteProduct = true
        this.isChanging = false
      }
    },

    deleteProduct (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.isDisabled = true
          this.isLoading = true
          services.deleteProduct({
            id: this.productForm.productId,
            desc: this.productForm.desc
          }).then(response => {
            if (response.status === 'success') {
              this.alertReporter('Амжилттай', `${this.productForm.productId} дугаартай бүтээгдэхүүн устлаа`, 'success')
              this.isLoading = false
              this.isDisabled = false
              this.$router.push({
                name: 'products'
              })
            } else if (response.status === 'error') {
              this.alertReporter('Амжилтгүй', response.message)
              this.isLoading = false
            }
          })
        }
      })
    },

    onPressSave (formName) {
      this.isNewForm = false
      this.productForm.sku = this.productForm.sku === '' ? this.skuGenerator : this.productForm.sku
      this.productForm.physical_size = this.addSuffix(this.productSize)
      this.productForm.price = parseFloat(this.productForm.price)
      this.productForm.get_price = parseFloat(this.productForm.get_price)
      this.productForm.discounted_price = parseFloat(this.productForm.discounted_price)
      this.productForm.tag_id = this.productForm.tag_id === '' ? 0 : this.productForm.tag_id
      if (this.productForm.group && this.productForm.group_order_discount_type === 'percent') {
        this.productForm.group.saled_price = 0
      } else {
        this.productForm.group.saled_percentage = 0
      }
      if (!Object.prototype.hasOwnProperty.call(this.productForm, 'is_group')) {
        this.productForm.group = {}
        this.productForm.is_group = false
      }
      this.productForm.is_content_check = this.is_content_check
      var tempImages = []
      this.tempImages.forEach(element => {
        tempImages.push(element.url)
      })
      this.productForm.image = tempImages
      var tempImagesPackage = []
      this.tempImagesPackage.forEach(element => {
        tempImagesPackage.push(element.url)
      })
      this.productForm.package_image = tempImagesPackage
      if (this.productForm.main_category_id !== '') {
        this.$refs[formName].validate((valid) => {
          if (valid && this.errorChecker.length === 0 && !this.isUploading) {
            var tempData = {
              productId: this.productForm.productId,
              product: this.productForm
            }
            this.isLoading = true
            services.updateProduct(this.productForm.productId, tempData).then(data => {
              this.isLoading = false
              this.alertReporter(data.status === 'success' ? 'Амжилттай' : 'Уучлаарай', data.message, data.status)
              if (data.status === 'success') {
                this.$router.go(-1)
              } else {
                this.isLoading = false
              }
            })
          } else {
            window.scrollTo(0, 0)
            return false
          }
        })
      } else {
        this.alertReporter('Алдаа', 'Алдаа гарлаа үндсэн категори сонгоно уу!', 'error')
        this.loading = false
      }
    },
    onPressItem (data) {
      this.$router.push({ name: 'stockDetail', params: { variant_id: data.variant_id } })
    },
    getProductData (productId) {
      this.isLoading = true
      services.getOneProduct(productId).then((response) => {
        if (response.status === 'success' && response.data.length !== 0) {
          const data = response.data[0]
          const categoryId = JSON.parse(JSON.stringify(data.category_id))
          this.is_content_check = data.is_content_check
          data.sale_check = data.sale_check ? data.sale_check : data.discounted_price > 0
          data.category_id = ''
          this.productForm = JSON.parse(JSON.stringify(data))
          this.getWarehousesBySupplierId()
          if (!Object.prototype.hasOwnProperty.call(data, 'group')) {
            this.productForm.group = {
              user_count: '',
              saled_price: 0,
              saled_percentage: 0,
              min_item_count: '',
              max_item_count: '',
              end_date: '',
              start_date: '',
              duration_time: ''
            }
          }
          if (this.productForm.group && this.productForm.group.saled_percentage === 0 && Object.prototype.hasOwnProperty.call(data, 'group')) {
            this.productForm.group_order_discount_type = 'amount'
          } else {
            this.productForm.group_order_discount_type = 'percent'
          }
          this.backUpProductData = JSON.parse(JSON.stringify(data))
          if (Object.prototype.hasOwnProperty.call(data, 'physical_size')) {
            this.productSize = {
              Weight: parseFloat(data.physical_size.Weight),
              Length: parseFloat(data.physical_size.Length),
              Width: parseFloat(data.physical_size.Width),
              Height: parseFloat(data.physical_size.Height),
              weightSuffix: data.physical_size.Weight.split(' ')[1],
              heightSuffix: data.physical_size.Height.split(' ')[1],
              widthSuffix: data.physical_size.Width.split(' ')[1],
              lengthSuffix: data.physical_size.Length.split(' ')[1]
            }
          } else {
            data.physical_size = {}
          }
          this.getCategory(categoryId)
          this.getSuppliers(data.supplier_id, data.brand_id)
          this.setImages(data.image)
          this.setPackageImages(data.package_image)
          this.getStatusOptions()
          this.isLoading = false
        } else {
          this.onPressClose()
        }
      })
    },
    getHistory (productId) {
      const query = '?id=' + productId + '&log_name=' + this.historyName
      services.getHistory(query).then(data => {
        this.historyData = data.data
        this.historyTotal = data.total
      })
    },
    getHistoryDetail (id) {
      this.historyLoading = true
      services.getHistoryDetail(id).then(response => {
        if (response.status === 'success' && response.data) {
          if (response.data.old) {
            this.historyDetailoldData = response.data.old
          }
          this.historyDetailnewData = response.data.new
          this.historyLoading = false
        } else {
          this.historyLoading = false
        }
      })
    },

    setImages (image) {
      if (image) {
        image.forEach(element => {
          this.tempImages.push({
            url: element
          })
        })
      }
    },

    setPackageImages (image) {
      if (image) {
        image.forEach(element => {
          this.tempImagesPackage.push({
            url: element
          })
        })
      }
    },

    getCollectionLists () {
      const query = '?search_text='
      services.getCollectionList(query).then(response => {
        if (response.status === 'success') {
          this.collectionList = response.data
        }
      })
    },

    handleRemove (file, type) {
      var tempId = 0
      if (type === 'image') {
        this.tempImages.forEach((element, index) => {
          if (element.uid === file.uid) {
            tempId = index
          }
        })
        this.tempImages.splice(tempId, 1)
      } else if (type === 'package') {
        this.tempImagesPackage.forEach((element, index) => {
          if (element.uid === file.uid) {
            tempId = index
          }
        })
        this.tempImagesPackage.splice(tempId, 1)
      } else {
        this.$message.error('Уучлаарай алдаа гарлаа!')
      }
    },

    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },

    getFile (file, type, fileList) {
      handlers.checkImageSize(file, type === 'image', 1020, 1020).then(() => {
        if (type === 'image') {
          this.isUploading = true
        } else if (type === 'package') {
          this.isUploadingPackage = true
        }
        handlers.getBase64(file.raw).then(res => {
          const tempBody = {
            image: res,
            filePath: file.name
          }
          services.imageUpload(tempBody).then(response => {
            if (type === 'image') {
              this.isUploading = false
            } else if (type === 'package') {
              this.isUploadingPackage = false
            }
            if (response.status === 'success') {
              if (type === 'image') {
                this.tempImages.push({
                  url: response.url
                })
              } else if (type === 'package') {
                this.tempImagesPackage.push({
                  url: response.url
                })
              }
            } else {
              this.$message.error('Уучлаарай алдаа гарлаа!')
            }
          })
        })
      }).catch((error) => {
        this.handleRemove(file, type)
        this.$message.error(error.message)
      })
    },

    getCategory (categoryId) {
      services.getCategoriesAsTree().then(data => {
        data.forEach(element => {
          element.label = element.name_mon
          element.id = element.uniq_id
          if (Object.prototype.hasOwnProperty.call(element, 'childrens')) {
            element.childrens.forEach(el => {
              el.label = el.name_mon
              el.id = el.uniq_id
              if (Object.prototype.hasOwnProperty.call(el, 'childrens')) {
                el.childrens.forEach(el1 => {
                  el1.label = el1.name_mon
                  el1.id = el1.uniq_id
                  if (Object.prototype.hasOwnProperty.call(el1, 'childrens')) {
                    el1.childrens.forEach(el2 => {
                      el2.label = el2.name_mon
                      el2.id = el2.uniq_id
                    })
                    el1.children = el1.childrens
                  }
                })
                el.children = el.childrens
              }
            })
            element.children = element.childrens
          }
        })
        this.categories = data
        this.productForm.category_id = categoryId
      })
    },

    getStatusOptions () {
      this.statusOption = [{
        value: 'active',
        label: 'Идэвхитэй'
      }, {
        value: 'inactive',
        label: 'Идэвхигүй'
      }]
    },

    getOptions () {
      this.options = [{
        value: 'Хэмжээ',
        label: 'Хэмжээ'
      }, {
        value: 'Өнгө',
        label: 'Өнгө'
      }, {
        value: 'Материал',
        label: 'Материал'
      }]
    },

    getBrands (supplier, brand) {
      this.isLoadBrand = true
      services.getBrandsBySupplierId(supplier).then(response => {
        this.isLoadBrand = false
        if (response.status === 'success') {
          this.brandOptions = response.data.datas
          this.productForm.brand_id = brand
        }
      })
    },

    onChangeSupplier (id) {
      this.isLoadBrand = true
      services.getBrandsBySupplierId(id).then(response => {
        this.isLoadBrand = false
        if (response.status === 'success') {
          this.brandOptions = response.data.datas
          this.productForm.supplier_id = id
          this.productForm.brand_id = ''
        }
      })
    },

    getSuppliers (supplier, brand) {
      services.getAllSuppliers().then(response => {
        this.suggestedSupplier = response.datas
        this.suggestedSupplier.forEach(element => {
          if (element.supplier_id === this.productForm.supplier_id) {
            this.taxPaid = element.is_tax_paid
          }
          if (this.taxPaid !== true) {
            const a = (((this.productForm.price / 1.1) - (this.productForm.get_price)) * 100) / (this.productForm.price / 1.1)
            this.percent = a
            this.percent = parseFloat(a).toFixed(2)
          } else {
            const b = (((this.productForm.price / 1.1) - (this.productForm.get_price / 1.1)) * 100) / (this.productForm.price / 1.1)
            this.percent = b
            this.percent = parseFloat(b).toFixed(2)
          }
          if (this.productForm.get_price === 0 || this.productForm.price === 0) {
            this.percent = 0
          }
        })
        if (supplier && supplier !== '') {
          this.productForm.supplier_id = supplier
          if (brand && brand !== '') {
            this.getBrands(supplier, brand)
          } else {
            this.productForm.brand_id = ''
            this.brandOptions = []
          }
        } else {
          this.$message.error('Уучлаарай нийлүүлэгчийн мэдээлэл олдсонгүй!')
        }
      })
    },

    filterSupplier () {
      services.searchSupplier('contract_accepted').then(response => {
        if (response.datas && Array.isArray(response.datas)) {
          this.suggestedSupplier = response.datas
        } else {
          this.suggestedSupplier = []
        }
      })
    },

    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'bottom-left'
      })
    },

    addSuffix (data) {
      var subData = {}
      subData.Weight = `${data.Weight} ${data.weightSuffix}`
      subData.Height = `${data.Height} ${data.heightSuffix}`
      subData.Width = `${data.Width} ${data.widthSuffix}`
      subData.Length = `${data.Length} ${data.lengthSuffix}`
      return subData
    },

    removeProperty (property) {
      this.productForm.properties = this.productForm.properties.filter(item => property !== item)
    },

    addProperty (e) {
      if (this.property.name && this.property.value) {
        this.productForm.properties = [...this.productForm.properties, { name: this.property.name, value: this.property.value }]
        this.property.name = ''
        this.property.value = ''
      }
    },

    onPressClose () {
      this.confirmLeaveForm = false
      this.$router.push({
        name: 'products',
        params: { supplierSelect: this.$route.params.data, brandSelect: this.$route.params.brndData }
      })
    }
  }
}
</script>

<style lang="css">
@import "~vue2-editor/dist/vue2-editor.css";

/* Import the Quill styles you want */
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
.labelColor .el-form-item__label {
  color: red;
}
</style>
